import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-f7d87c64"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["value", "data-filter"]
const _hoisted_2 = {
  key: 0,
  class: "Facet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", null, [
      _withDirectives(_createElementVNode("input", {
        type: "checkbox",
        class: "CheckboxInput",
        value: _ctx.optionValue,
        "data-filter": _ctx.filterKey,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptions) = $event)),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('areaFilterChange', _ctx.optionValue)))
      }, null, 8 /* PROPS */, _hoisted_1), [
        [_vModelCheckbox, _ctx.selectedOptions]
      ]),
      _createElementVNode("span", null, _toDisplayString(_ctx.optionName), 1 /* TEXT */),
      (_ctx.displayFacets)
        ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.facet), 1 /* TEXT */))
        : _createCommentVNode("v-if", true),
      _renderSlot(_ctx.$slots, "ExpandCollapseToggle", {}, undefined, true)
    ])
  ]))
}