import { Component, defineComponent, inject } from "vue";

import ContinentFilter from "./continent/ContinentFilter.vue";
import CountryFilter from "./country/CountryFilter.vue";
import LocationFilter from "./LocationFilter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/models/FilterProviderKey";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { LocationFilterType } from "@/models/LocationFilterType";

export default defineComponent({
	components: {
		ContinentFilter: ContinentFilter as Component,
		CountryFilter: CountryFilter as Component
	},

	setup: () => {

		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`"${FilterProviderKey.FILTER_PROVIDER}" was not provided for LocationFilter`);
		}

		const locationFilterProvider: ILocationFilterProvider | undefined = inject(FilterProviderKey.LOCATION_FILTER_PROVIDER);
		if (locationFilterProvider == null) {
			throw new Error(`"${FilterProviderKey.LOCATION_FILTER_PROVIDER}" was not provided for LocationFilter`);
		}

		const component = new LocationFilter(filterProvider, locationFilterProvider);

		return {
			collapsed: component.collapsed,
			countryFilterHintCount: component.countryFilterHintCount,
			continentFilterHintCount: component.continentFilterHintCount,
			activeLocationFilterType: component.activeLocationFilterType,
			toggleContinentFilter: component.toggleContinentFilter.bind(component),
			toggleCountryFilter: component.toggleCountryFilter.bind(component),
			toggleFilter: component.toggleFilter.bind(component),
			includeContinents: locationFilterProvider.includeContinents,
			LocationFilterType,
			filterProvider
		}
	}
});
