

import { defineComponent, inject } from "vue";

import DisciplineFilter from "./DisciplineFilter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/models/FilterProviderKey";

export default defineComponent({
	setup: () => {

		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`"${FilterProviderKey.FILTER_PROVIDER}" was not provided for DisciplineFilter`);
		}

		const component = new DisciplineFilter(filterProvider);

		return {
			key: component.key,
			filterOptions: component.filterOptions,
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			selectedDiscipline: component.selectedDiscipline,
			disciplineFilterOptions: component.disciplineFilterOptions,
			displayFacets: component.displayFacets,
			isDisciplineSelected: component.isDisciplineSelected,
			facet: component.facet.bind(component),
			toggleFilter: component.toggleFilter.bind(component),
			removeSelection: component.removeSelection.bind(component),
			showDiscipline: component.showDiscipline.bind(component),
			selectOption: component.selectOption.bind(component),
			getIconOf: component.getIconOf.bind(component)
		}
	}
});
