import { Component, defineComponent, inject } from "vue";

import ContinentFilter from "./ContinentFilter.class";
import Checkbox from "../../checkbox/Checkbox.vue";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/models/FilterProviderKey";

export default defineComponent({
	components: {
		Checkbox: Checkbox as Component
	},
	setup: () => {
		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`"${FilterProviderKey.FILTER_PROVIDER}" was not provided for ContinentFilter`);
		}

		const component = new ContinentFilter(filterProvider);

		return {
			continentFilterOptions: component.continentFilterOptions,
			key: component.key,
			filterProvider
		}
	}
});

