import { vModelCheckbox as _vModelCheckbox, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, withModifiers as _withModifiers, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-713c8bd0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "CheckboxRow" }
const _hoisted_2 = ["value", "data-filter"]
const _hoisted_3 = { class: "FacetContainer" }
const _hoisted_4 = {
  key: 0,
  class: "Facet"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("label", _hoisted_1, [
      _createElementVNode("div", null, [
        _withDirectives(_createElementVNode("input", {
          type: "checkbox",
          class: "CheckboxInput",
          value: _ctx.optionValue,
          "data-filter": _ctx.filterKey,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedOptions) = $event)),
          onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setSelectedOptions()))
        }, null, 8 /* PROPS */, _hoisted_2), [
          [_vModelCheckbox, _ctx.selectedOptions]
        ]),
        _createElementVNode("span", null, _toDisplayString(_ctx.optionName), 1 /* TEXT */)
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_ctx.displayFacets)
          ? (_openBlock(), _createElementBlock("span", _hoisted_4, _toDisplayString(_ctx.facet), 1 /* TEXT */))
          : _createCommentVNode("v-if", true),
        (_ctx.hasAreas)
          ? (_openBlock(), _createElementBlock("div", {
              key: 1,
              class: "AreaSwitcher",
              onClick: _cache[2] || (_cache[2] = _withModifiers(($event: any) => (_ctx.toggleExpandedAreas(_ctx.optionValue)), ["stop","prevent"]))
            }, [
              _createElementVNode("i", {
                class: _normalizeClass(["ToggleIcon", [_ctx.expandCheckbox ? 'lnr-chevron-up' : 'lnr-chevron-down']])
              }, null, 2 /* CLASS */)
            ]))
          : _createCommentVNode("v-if", true)
      ])
    ])
  ]))
}