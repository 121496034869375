export enum FilterKey {
	KEYWORD_WHAT = "kw-what",
	KEYWORD_WHERE = "kw-where",
	KEYWORD = "kw",
	ORGANISATIONS = "oi",
	DISCIPLINES = "di",
	EDUCATION_LEVEL = "lv",
	CONTINENT = "rg",
	COUNTRY = "ci",
	AREA = "ae",
	TUITION_FEE = "tr",
	DURATION = "dur",
	ATTENDANCE = "de",
	DELIVERY_METHOD = "mh",
	DEGREE_TYPE = "dg",
	EDUCATIONAL_FORM = "ef",
	SPECIAL_PROGRAMMES = "sps",
	USER_COUNTRY = "uc",
	USER_REGION = "ur",
	CURRENCY = "tc"
}
