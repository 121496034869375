import { Component, defineComponent, inject } from "vue";

import AreaCheckbox from "@/presentation/checkbox/area-checkbox/AreaCheckbox.vue"
import { AreaFilter } from "./AreaFilter.class";
import { CountryFilterContext } from "@/presentation/location/country/CountryFilterContext";

export default defineComponent({
	components: {
		AreaCheckbox: AreaCheckbox as Component
	},
	props: {
		countryId: {
			type: String,
			required: true
		}
	},
	emits: ["areaFilterChange"],
	setup: (props) => {
		const context: CountryFilterContext | undefined = inject(CountryFilterContext.key);
		if (context == null) {
			throw new Error(`"${CountryFilterContext.key}" class was not provided for AreaFilter`);
		}

		const component = new AreaFilter(props.countryId, context.filterProvider,
			context.expandability);

		return {
			key: component.key,
			areaFilterOptions: component.areaFilterOptions,
			showArea: component.showArea,
			isAreaSelected: component.isAreaSelected.bind(component)
		}
	}
});
