import { Ref } from "vue";
import { FilterKey } from "@/models/FilterKey";
import { AreaFilterOptionInformation } from "@/models/AreaFilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { CountryExpandability } from "@/presentation/location/country/CountryExpandability";

export class AreaFilter {

	public readonly key = FilterKey.AREA;

	public readonly countryId: string;
	public readonly areaFilterOptions: AreaFilterOptionInformation[];

	public readonly showArea: Ref<boolean>;

	constructor(
		countryId: string,
		private filterProvider: IFilterProvider,
		private countryExpandability: CountryExpandability
	) {
		this.countryId = countryId;
		this.showArea = this.countryExpandability.isExpandedFor(countryId);

		const areas = this.filterProvider.getFilterOptionInformation(this.key) as AreaFilterOptionInformation[];
		this.areaFilterOptions = areas.sort((a, b) => a.optionName.localeCompare(b.optionName));
	}

	public isAreaSelected(area: string): boolean {
		return this.filterProvider.getFilterSelection(this.key).includes(area);
	}
}
