import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { FilterKey } from "@/models/FilterKey";

export class CountryFilterSelection {

	public constructor(private filterProvider: IFilterProvider, private locationFilterProvider: ILocationFilterProvider) {
		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;
	}

	public async process(value: string): Promise<void> {

		const areasForCountry = this.locationFilterProvider.identifyAreasFor(value);

		const areaFilterSelection = this.filterProvider.getFilterSelection(FilterKey.AREA)
			.filter(val => areasForCountry.includes(val));

		const areaPayload = areaFilterSelection.map(val => {
			return {
				key: FilterKey.AREA,
				value: val
			}
		});

		await this.filterProvider.processFiltersSelection([{
			key: FilterKey.COUNTRY,
			value
		}, ...areaPayload]);
	}
}
