import { defineComponent, inject } from "vue";

import { CountryCheckbox } from "./CountryCheckbox.class";
import { CountryCheckboxOptions } from "@/interfaces/ICountryCheckboxOptions";
import { CountryFilterContext } from "@/presentation/location/country/CountryFilterContext";

export default defineComponent({
	props: {
		optionName: String,
		optionValue: String,
		hasAreas: Boolean
	},
	setup: (props: any) => {
		const context: CountryFilterContext | undefined = inject(CountryFilterContext.key);
		if (context == null) {
			throw new Error(`"${CountryFilterContext.key}" class was not provided for CountryCheckbox`);
		}

		const countryCheckboxOptions = props as CountryCheckboxOptions;
		const component = new CountryCheckbox(
			countryCheckboxOptions.optionName,
			countryCheckboxOptions.optionValue,
			countryCheckboxOptions.hasAreas,
			context.filterProvider ,
			context.locationFilterProvider,
			context.expandability
		);

		return {
			facet: component.facet,
			selectedOptions: component.selectedOptions,
			expandCheckbox: component.expandCheckbox,
			filterKey: component.filterKey,
			setSelectedOptions: component.setSelectedOptions.bind(component),
			toggleExpandedAreas: component.toggleExpandedAreas.bind(component),
			displayFacets: component.displayFacets
		}
	}
});
