import { computed } from "vue";
import { FilterKey } from "@/models/FilterKey";
import Filter from "@/Filter.class";
import { DisciplineFilterOptionInformation } from "@/models/DisciplineFilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { disciplineGetDefaultIcon } from "./DisciplineGetDefaultIcon";

export default class DisciplineFilter extends Filter {

	public isDisciplineSelected = computed((): boolean => {
		return this.selectedDiscipline.value !== undefined;
	});

	public selectedDiscipline = computed((): string => {
		return this.filterProvider.getFilterSelection(this.key)[0];
	})

	public disciplineFilterOptions = computed((): DisciplineFilterOptionInformation[] => {
		const result = this.filterOptions as DisciplineFilterOptionInformation[];
		return result
	})

	public constructor(filterProvider: IFilterProvider) {
		super(FilterKey.DISCIPLINES, filterProvider);
	}

	public get selectedParentIdentity(): string | undefined {
		const disciplineId = this.selectedDiscipline.value;

		if (!disciplineId) {
			return;
		}

		const filterOption = (this.filterOptions as DisciplineFilterOptionInformation[])
			.filter(_ => _.optionValue === disciplineId)[0];

		if (filterOption) {
			return filterOption.parentIdentity;
		}

		return;
	}

	public facet(optionValue: string): number {
		const filterFacets = this.filterProvider.getFacet(this.key, optionValue);
		return filterFacets ?? 0;
	}

	public displayFacets = computed((): boolean => {
		return this.filterProvider.displayFacets;
	});

	public async selectOption(discipline: DisciplineFilterOptionInformation): Promise<void> {
		if (discipline.optionValue === this.selectedDiscipline.value) {
			return;
		}

		await this.filterProvider.processFilterSelection({
			key: this.key,
			value: discipline.optionValue
		});
	}

	public async removeSelection(): Promise<void> {
		await this.filterProvider.processFilterSelection({
			key: this.key,
			value: this.selectedDiscipline.value
		});
	}

	public getIconOf(discipline: DisciplineFilterOptionInformation): string | undefined {
		return discipline.icon ?? disciplineGetDefaultIcon(discipline.optionValue);
	}

	public showDiscipline(id: string, parentId: string | undefined): boolean {
		if (parentId === undefined) {
			return this.showMainDiscipline(id, parentId);
		}

		return this.showSubdiscipline(parentId);
	}

	public showMainDiscipline(id: string, parentId: string | undefined): boolean {
		return this.noDisciplineSelected()
			|| this.mainDisciplineSelected(id, parentId)
			|| this.subDisciplineSelected(id);
	}

	public subDisciplineSelected(id: string): boolean {
		return id === this.selectedParentIdentity;
	}

	public showSubdiscipline(parentId: string): boolean {
		return (this.existsDisciplineSelected()
			&& parentId === this.selectedDiscipline.value)
			|| parentId === this.selectedParentIdentity;
	}

	public mainDisciplineSelected(id: string, parentId: string | undefined): boolean {
		return id === this.selectedDiscipline.value
			&& parentId === undefined;
	}

	public existsDisciplineSelected(): boolean {
		return this.selectedDiscipline.value !== undefined;
	}

	public noDisciplineSelected(): boolean {
		return this.selectedDiscipline.value === undefined;
	}
}
