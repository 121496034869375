import { Component, defineComponent, inject } from "vue";

import Checkbox from "../checkbox/Checkbox.vue";
import EducationLevelFilter from "./EducationLevelFilter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/models/FilterProviderKey";

export default defineComponent({
	components: {
		Checkbox: Checkbox as Component
	},
	setup: () => {

		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`"${FilterProviderKey.FILTER_PROVIDER}" was not provided for EducationLevelFilter`);
		}

		const component = new EducationLevelFilter(filterProvider);

		return {
			key: component.key,
			filterOptions: component.filterOptions,
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			toggleFilter: component.toggleFilter.bind(component),
			filterProvider
		}
	}
});
