import { computed } from "vue";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "@/models/FilterKey";

export default class Checkbox {
	public facet = computed((): number | string => {

		const facet = this.filterProvider.getFacet(this.filterKey, this.optionValue);
		return facet ?? 0;
	});

	public displayFacets = computed((): boolean => {
		return this.filterProvider.displayFacets;
	});

	public selectedOptions = computed((): readonly string[] => {
		return this.filterProvider.getFilterSelection(this.filterKey);
	});

	constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly filterKey: FilterKey,
		public readonly filterProvider: IFilterProvider
	) {
	}

	public async setSelectedOptions(): Promise<void> {
		await this.filterProvider.processFilterSelection({
			key: this.filterKey,
			value: this.optionValue
		});
	}
}
