import { defineComponent, onMounted, inject } from "vue";

import TuitionFeeFilter from "./TuitionFeeFilter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/models/FilterProviderKey";
import { ICurrencyFilterProvider } from "@/interfaces/filter-providers/ICurrencyFilterProvider";

export default defineComponent({
	setup: () => {

		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`${FilterProviderKey.FILTER_PROVIDER} was not provided for TuitionFeeFilter`);
		}

		const currencyFilterProvider: ICurrencyFilterProvider | undefined = inject(FilterProviderKey.CURRENCY_FILTER_PROVIDER);
		if (currencyFilterProvider == null) {
			throw new Error(`${FilterProviderKey.CURRENCY_FILTER_PROVIDER} was not provided for TuitionFeeFilter`);
		}
		const component = new TuitionFeeFilter(filterProvider, currencyFilterProvider);

		onMounted(component.onMounted.bind(component));

		return {
			key: component.key,
			filterOptions: component.filterOptions,
			collapsed: component.collapsed,
			filterHintCount: component.filterHintCount,
			toSelected: component.toSelected,
			fromSelected: component.fromSelected,
			maxValue: component.maxValue,
			userCurrency: component.userCurrency,
			fromSelectedFormatted: component.fromSelectedFormatted,
			toSelectedFormatted: component.toSelectedFormatted,
			fromSlider: component.fromSlider,
			toSlider: component.toSlider,
			sliderStepSize: component.sliderStepSize,
			toggleFilter: component.toggleFilter.bind(component),
			changeCurrencyClicked: component.changeCurrencyClicked.bind(component),
			updateFromSliderValue: component.updateFromSliderValue.bind(component),
			updateToSliderValue: component.updateToSliderValue.bind(component),
			updateFromInputValue: component.updateFromInputValue.bind(component),
			updateToInputValue: component.updateToInputValue.bind(component)
		}
	}
});
