import { computed, ref, ComputedRef } from 'vue';

export class CountryExpandability {
	private expandedCountries = ref([] as string[]);

	public isExpandedFor = (countryId: string | undefined): ComputedRef<boolean> => computed((): boolean => {
		if (countryId == null) {
			return false;
		}
		const isExpanded = this.expandedCountries.value.includes(countryId);
		return isExpanded;
	});

	public toggleExpandability(countryId: string): void {
		if (this.expandedCountries.value.includes(countryId)) {
			this.expandedCountries.value.splice(this.expandedCountries.value.indexOf(countryId), 1);
			return;
		}

		this.expandedCountries.value.push(countryId);
	}
}
