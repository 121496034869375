import { FilterKey } from "@/models/FilterKey";
import { FilterOptionInformation } from "@/models/FilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";

export default class ContinentFilter {
	public readonly key = FilterKey.CONTINENT;
	public continentFilterOptions: readonly FilterOptionInformation[];

	constructor(private filterProvider: IFilterProvider) {
		this.continentFilterOptions = this.filterProvider.getFilterOptionInformation(this.key);
	}
}
