import { defineComponent, inject } from "vue";
import Checkbox from "./Checkbox.class";
import { CheckboxOptions } from "@/interfaces/ICheckboxOptions";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { FilterProviderKey } from "@/models/FilterProviderKey";

export default defineComponent({
	props: {
		optionName: String,
		optionValue: String,
		filterKey: String
	},
	setup: (props) => {

		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`"${FilterProviderKey.FILTER_PROVIDER}" was not provided for Checkbox`);
		}

		const checkboxOptions = props as CheckboxOptions;

		const component = new Checkbox(
			checkboxOptions.optionName,
			checkboxOptions.optionValue,
			props.filterKey as FilterKey,
			filterProvider
		);

		return {
			facet: component.facet,
			selectedOptions: component.selectedOptions,
			setSelectedOptions: component.setSelectedOptions.bind(component),
			displayFacets: component.displayFacets
		}
	}
});
