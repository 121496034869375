import { IFilterProvider } from "../../../interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "../../../interfaces/filter-providers/ILocationFilterProvider";
import { CountryExpandability } from "./CountryExpandability";

export class CountryFilterContext {
	public static readonly key = 'countryFilterContext';

	public readonly filterProvider: IFilterProvider;
	public readonly locationFilterProvider: ILocationFilterProvider;
	public readonly expandability: CountryExpandability;

	public constructor(filterProvider: IFilterProvider,
		locationFilterProvider: ILocationFilterProvider, expandability: CountryExpandability) {

		this.filterProvider = filterProvider;
		this.locationFilterProvider = locationFilterProvider;
		this.expandability = expandability;
	}
}