import { computed, ref, Ref } from "vue";

import { FilterKey } from "@/models/FilterKey";
import { IOption } from "@studyportals/multiselect/src/interfaces/options.interface";
import { CountryFilterOptionInformation } from "@/models/CountryFilterOptionInformation";
import { FilterOptionInformation } from "@/models/FilterOptionInformation";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { OnSelectEvent } from "@/interfaces/IOnSelectEvent";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { AreaFilterSelection } from "../area/AreaFilterSelection";

export class CountryFilter {
	public readonly key = FilterKey.COUNTRY;
	public showMultiselect: Ref<boolean>;

	public countryFilterOptions = computed((): CountryFilterOptionInformation[] => {
		const visibleCountryOptions: CountryFilterOptionInformation [] = [];
		const topCountryIds: string[] = this.locationFilterProvider.identifyTopCountries();

		topCountryIds.forEach(_ => {
			const filterOption = this.filterProvider.getFilterOptionInformationById(this.key, _);

			if (filterOption !== null) {
				visibleCountryOptions.push(filterOption as CountryFilterOptionInformation);
			}
		});

		const filterOptionInformation = this.filterProvider.getFilterOptionInformation(this.key);
		const selectedCountries = this.getSelectedCountries(filterOptionInformation, topCountryIds);
		selectedCountries.forEach(_ => visibleCountryOptions.push(_ as CountryFilterOptionInformation));

		return visibleCountryOptions;
	});

	private getSelectedCountries(
		filterInformation: readonly FilterOptionInformation[],
		topCountryIds: string[]): FilterOptionInformation[]
	{
		return filterInformation.filter(
			_ => this.isCountrySelected(_.optionValue) && !topCountryIds.includes(_.optionValue)
		);
	}

	public transformedCountryFormat = computed((): IOption[] => {
		const filterOptionInformation = this.filterProvider.getFilterOptionInformation(this.key);
		return filterOptionInformation.map(countryFilterOption => {
			return {
				label: countryFilterOption.optionName,
				value: countryFilterOption.optionValue
			}
		})
	});

	public constructor(
		private filterProvider: IFilterProvider,
		private locationFilterProvider: ILocationFilterProvider
	) {
		this.showMultiselect = ref(false);
	}

	public displayMultiselect(): void {
		this.showMultiselect.value = true;
	}

	public isCountrySelected(countryId: string): boolean {
		return this.filterProvider.getFilterSelection(this.key).includes(countryId);
	}

	public async onSelect(event: OnSelectEvent): Promise<void> {
		if (!event) {
			return;
		}

		this.showMultiselect.value = false;

		await this.filterProvider.processFilterSelection({
			key: this.key,
			value: event.value
		});
	}

	public async processAreaFilterChange(value: string): Promise<void> {
		const areaFilterSelection = new AreaFilterSelection(this.filterProvider, this.locationFilterProvider);
		return await areaFilterSelection.process(value);
	}
}
