import { Component, defineComponent, inject, provide } from "vue";

import AreaFilter from "../area/AreaFilter.vue";
import CountryCheckbox from "@/presentation/checkbox/country-checkbox/CountryCheckbox.vue";
import { MultiSelect } from "@studyportals/multiselect";
import { CountryFilter } from "./CountryFilter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterProviderKey } from "@/models/FilterProviderKey";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { CountryExpandability } from "./CountryExpandability";
import { CountryFilterContext } from "./CountryFilterContext";

export default defineComponent({
	components: {
		Areas: AreaFilter as Component,
		CountryCheckbox: CountryCheckbox as Component,
		MultiSelect
	},

	setup: () => {
		const filterProvider: IFilterProvider | undefined = inject(FilterProviderKey.FILTER_PROVIDER);
		if (filterProvider == null) {
			throw new Error(`"${FilterProviderKey.FILTER_PROVIDER}" was not provided for CountryFilter`);
		}

		const locationFilterProvider: ILocationFilterProvider | undefined = inject(FilterProviderKey.LOCATION_FILTER_PROVIDER);
		if (locationFilterProvider == null) {
			throw new Error(`"${FilterProviderKey.LOCATION_FILTER_PROVIDER}" was not provided for CountryFilter`);
		}

		const context = new CountryFilterContext(filterProvider, locationFilterProvider, new CountryExpandability());
		provide(CountryFilterContext.key, context);

		const component = new CountryFilter(filterProvider, locationFilterProvider);

		return {
			countryFilterOptions: component.countryFilterOptions,
			key: component.key,
			showMultiselect: component.showMultiselect,
			transformedCountryFormat: component.transformedCountryFormat,
			processAreaFilterChange: component.processAreaFilterChange.bind(component),
			displayMultiselect: component.displayMultiselect.bind(component),
			onSelect: component.onSelect.bind(component)
		}
	}
});
