import { Ref } from "vue";
import { FilterKey } from "@/models/FilterKey";
import Checkbox from "@/presentation/checkbox/Checkbox.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";
import { CountryExpandability } from "@/presentation/location/country/CountryExpandability";
import { CountryFilterSelection } from "@/presentation/location/country/CountryFilterSelection";

export class CountryCheckbox extends Checkbox {
	public readonly expandCheckbox: Ref<boolean>;
	public readonly filterKey = FilterKey.COUNTRY;

	public constructor(
		public readonly optionName: string,
		public readonly optionValue: string,
		public readonly hasAreas: boolean,
		public readonly filterProvider: IFilterProvider,
		public readonly locationFilterProvider: ILocationFilterProvider,
		private readonly countryExpandability: CountryExpandability
	) {
		super(optionName, optionValue, FilterKey.COUNTRY, filterProvider);

		this.expandCheckbox = this.countryExpandability?.isExpandedFor(optionValue);
	}

	public async setSelectedOptions(): Promise<void> {
		if (this.optionValue === undefined) {
			return;
		}

		const countryFilterSelection = new CountryFilterSelection(this.filterProvider, this.locationFilterProvider);
		return await countryFilterSelection.process(this.optionValue);
	}

	public toggleExpandedAreas(countryId: string | undefined): void {
		if (countryId == null) {
			return;
		}
		this.countryExpandability.toggleExpandability(countryId);
	}
}
