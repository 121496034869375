import { defineComponent, inject } from "vue";
import AreaCheckbox from "./AreaCheckbox.class";
import { CheckboxOptions } from "@/interfaces/ICheckboxOptions";
import { CountryFilterContext } from "@/presentation/location/country/CountryFilterContext";
import { FilterKey } from "../../../models/FilterKey";

export default defineComponent({
	props: {
		optionName: String,
		optionValue: String,
		filterKey: String
	},
	emits: ["areaFilterChange"],
	setup: (props) => {
		const context: CountryFilterContext | undefined = inject(CountryFilterContext.key);
		if (context == null) {
			throw new Error(`"${CountryFilterContext.key}" class was not provided for AreaCheckbox`);
		}

		const checkboxOptions = props as CheckboxOptions;
		const component = new AreaCheckbox(
			checkboxOptions.optionName,
			checkboxOptions.optionValue,
			props.filterKey as FilterKey,
			context.filterProvider
		);

		return {
			facet: component.facet,
			selectedOptions: component.selectedOptions,
			displayFacets: component.displayFacets
		}
	}
});
