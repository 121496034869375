import { computed, Ref, ref, watch, watchEffect, nextTick } from "vue";
import Filter from "../../Filter.class";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { FilterKey } from "@/models/FilterKey";
import { ICurrencyFilterProvider } from "@/interfaces/filter-providers/ICurrencyFilterProvider";
import { IFilterPayload } from "@/interfaces/IFilterPayload";

export default class TuitionFeeFilter extends Filter {

	public fromSlider: Ref<HTMLInputElement | null> = ref(null);
	public toSlider: Ref<HTMLInputElement | null> = ref(null);
	private unwatch: () => void;

	private debounceTimeout?: NodeJS.Timeout;
	private debounceTime = 0;

	private readonly euro = 'EUR';
	private originalCurrency = this.euro as string;

	public showSliders = ref(true)

	public maxValue = ref(50000)
	public toSelected = ref(this.maxValue.value);
	public fromSelected = ref(0);

	private shouldShowDecimals = computed(() => {

		return 50000 / this.maxValue.value > 1000
	})

	public sliderStepSize = computed(() => {
		if (this.shouldShowDecimals.value) {
			return 0.01
		}

		return 1
	})

	public fromSelectedFormatted = computed({
		get: () => {
			if (this.shouldShowDecimals.value) {
				return this.fromSelected.value.toString()
			}
			return new Intl.NumberFormat().format(this.fromSelected.value)
		},
		set: (newVal: string) => {
			const parsedValue = parseFloat(newVal.replace(/\D/g, ''));
			this.fromSelected.value = isNaN(parsedValue) ? 0 : parsedValue;
		}
	})

	public toSelectedFormatted = computed({
		get: () => {
			if (this.toSelected.value === this.maxValue.value) {
				return `No Max`
			}

			if (this.shouldShowDecimals.value) {
				return this.toSelected.value.toString()
			}

			return new Intl.NumberFormat().format(this.toSelected.value)
		},
		set: (newVal: string) => {
			const parsedValue = parseFloat(newVal.replace(/\D/g, ''));
			this.toSelected.value = isNaN(parsedValue) ? this.maxValue.value : parsedValue;
		}
	})

	public filterValues = computed((): readonly string[] => {
		return this.filterProvider.getFilterSelection(this.key);
	});

	public userCurrency = computed((): string => {
		return this.currencyFilterProvider.getCurrency()
	});

	public areDependenciesLoaded = computed((): boolean => {
		return this.currencyFilterProvider.getAreDependenciesLoaded()
	});

	constructor(
		public filterProvider: IFilterProvider,
		private currencyFilterProvider: ICurrencyFilterProvider
	) {
		super(FilterKey.TUITION_FEE, filterProvider);
	}

	public async onMounted(): Promise<void> {

		if (this.areDependenciesLoaded.value) {
			await this.initializeFilter();
			return;
		}

		watch(this.areDependenciesLoaded, async (areDependenciesLoaded) => {
			if (!areDependenciesLoaded) return;
			await this.initializeFilter();
		})
	}

	private async initializeFilter(): Promise<void> {

		// Only watch for the initial filters, which may load in async
		this.unwatch = watchEffect(() => {
			if (this.filterValues.value) {
				void this.setInitialFilterValues()
			}
		});

		watch(this.userCurrency, () => this.processCurrencyUpdated());
		await this.processCurrencyUpdated();
	}

	public debounce(callbackFunction: () => void): void {

		if (this.debounceTimeout) {
			clearTimeout(this.debounceTimeout);
		}

		this.debounceTimeout = setTimeout(() => {
			if (this.debounceTime < Date.now()) {
				callbackFunction();
				this.debounceTime = Date.now() + 400;
			}
		}, 500)
	}

	private roundValue(unrounded: number): number {
		if (this.shouldShowDecimals.value) {
			return Math.round(unrounded * 100) / 100

		}

		return Math.round(unrounded)
	}

	private async processCurrencyUpdated(): Promise<void> {

		if (this.originalCurrency === this.userCurrency.value) {
			return;
		}

		const convertedMaxValue = await this.currencyFilterProvider.currencyConvert(
			this.maxValue.value,
			this.originalCurrency,
			this.userCurrency.value
		);
		const convertedToSelected = await this.currencyFilterProvider.currencyConvert(
			this.toSelected.value,
			this.originalCurrency,
			this.userCurrency.value
		);
		const convertedFromSelected = await this.currencyFilterProvider.currencyConvert(
			this.fromSelected.value,
			this.originalCurrency,
			this.userCurrency.value
		);

		this.originalCurrency = this.userCurrency.value;

		this.maxValue.value = this.roundValue(convertedMaxValue);

		await nextTick(() => {
			this.toSelected.value = this.roundValue(convertedToSelected)
			this.fromSelected.value = this.roundValue(convertedFromSelected)
		})

	}

	private async setInitialFilterValues(): Promise<void> {
		if (!this.filterValues.value.length) {
			return;
		}

		try {

			const parsedValues = JSON.parse(this.filterValues.value[0]) as number[];

			let toVal = parsedValues[1];
			let fromVal = parsedValues[0]

			if (toVal === -1) {
				toVal = this.maxValue.value
			}

			// Filters are always saved in EUR, convert to user currency
			fromVal = await this.currencyFilterProvider.currencyConvert(fromVal, this.euro, this.userCurrency.value)
			toVal = await this.currencyFilterProvider.currencyConvert(toVal, this.euro, this.userCurrency.value)


			this.fromSelected.value = this.roundValue(fromVal);
			this.toSelected.value = this.roundValue(toVal);

			this.updateFromSliderValue();
			this.updateToSliderValue();
			this.unwatch();
		}
		catch {
			console.warn('Unable to set initial Tuition Fee filter')
		}
	}

	private fillSlider(): void {
		if (!this.fromSlider.value || !this.toSlider.value) {
			return;
		}

		const toMin = Number(this.toSlider.value.min);
		const toMax = Number(this.toSlider.value.max);

		const rangeDistance = toMax - toMin;
		const fromPosition = this.fromSelected.value - toMin;
		const toPosition = this.toSelected.value - toMin;

		const sliderColor = '#B8C6CE'; //$GreyL
		const rangeColor = '#3F5C6E'; //$GreyD

		this.toSlider.value.style.background = `linear-gradient(
		  to right,
		  ${sliderColor} 0%,
		  ${sliderColor} ${(fromPosition) / (rangeDistance) * 100}%,
		  ${rangeColor} ${((fromPosition) / (rangeDistance)) * 100}%,
		  ${rangeColor} ${(toPosition) / (rangeDistance) * 100}%, 
		  ${sliderColor} ${(toPosition) / (rangeDistance) * 100}%, 
		  ${sliderColor} 100%)`;
	}

	private async setSelectedOptions(): Promise<void> {

		let convertedFromVal = this.fromSelected.value;
		let convertedToVal = this.toSelected.value;

		// Filters are always saved in EUR, convert from user currency
		convertedFromVal = await this.currencyFilterProvider.currencyConvert(
			this.fromSelected.value,
			this.originalCurrency,
			this.euro
		);
		convertedToVal = await this.currencyFilterProvider.currencyConvert(
			this.toSelected.value,
			this.originalCurrency,
			this.euro
		);

		const toValue = Number(this.toSelected.value) === this.maxValue.value ? -1 : Math.round(convertedToVal);
		const options = `[${Math.round(convertedFromVal)},${toValue}]`;

		await this.filterProvider.processFilterSelection({
			key: 'tr',
			value: options
		} as IFilterPayload);
	}

	private trackClickEvent(inputType: string): void {
		this.currencyFilterProvider.trackCustomClickEvent(`tuition_filter_click`, inputType);
	}

	public updateFromInputValue(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.fromSelectedFormatted.value = target.value;

		this.updateFromSliderValue(event, 'text');
	}

	public updateToInputValue(event: Event): void {
		const target = event.target as HTMLInputElement;
		this.toSelectedFormatted.value = target.value;

		this.updateFromSliderValue(event, 'text');
	}

	public updateFromSliderValue(event?: Event, inputType?: string): void {

		const toSelected = Number(this.toSelected.value);
		const fromSelected = Number(this.fromSelected.value);

		const maxAllowedValue = this.roundValue(toSelected - (this.maxValue.value * 0.05))

		if (fromSelected >= maxAllowedValue) {
			event?.preventDefault();

			const newFrom = maxAllowedValue;
			this.fromSelected.value = newFrom <= 0 ? 0 : newFrom
		}

		this.fillSlider()
		this.debounce(() => {
			void this.setSelectedOptions()

			if (inputType) {
				this.trackClickEvent(inputType)
			}
		});
	}

	public updateToSliderValue(event?: Event, inputType?: string): void {

		let toSelected = Number(this.toSelected.value);
		const fromSelected = Number(this.fromSelected.value);


		if (toSelected >= this.maxValue.value) {
			toSelected = this.maxValue.value
			this.toSelected.value = toSelected;
		}

		const minAllowedValue = this.roundValue(fromSelected + (this.maxValue.value * 0.05))

		if (minAllowedValue >= toSelected) {
			event?.preventDefault();

			this.toSelected.value = minAllowedValue;
		}

		this.fillSlider();
		this.debounce(() => {
			void this.setSelectedOptions()

			if (inputType) {
				this.trackClickEvent(inputType)
			}
		});
	}

	public changeCurrencyClicked(): void {
		this.currencyFilterProvider.requestCurrencyChange();
	}
}
