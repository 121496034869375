import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e8027c6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "ContinentFilterWrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Checkbox = _resolveComponent("Checkbox")!

  return (_openBlock(), _createElementBlock("ul", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.continentFilterOptions, (item) => {
      return (_openBlock(), _createElementBlock("li", null, [
        _createVNode(_component_Checkbox, {
          filterKey: _ctx.key,
          optionName: item.optionName,
          optionValue: item.optionValue
        }, null, 8 /* PROPS */, ["filterKey", "optionName", "optionValue"])
      ]))
    }), 256 /* UNKEYED_FRAGMENT */))
  ]))
}