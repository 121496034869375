import { computed, ref } from "vue";
import { FilterKey } from "@/models/FilterKey";
import { IFilterProvider } from "@/interfaces/filter-providers/IFilterProvider";
import { LocationFilterType } from "@/models/LocationFilterType";
import { ILocationFilterProvider } from "@/interfaces/filter-providers/ILocationFilterProvider";

export default class LocationFilter {

	public readonly selectedLocationFilterType = ref<LocationFilterType | null>(null);

	public readonly activeLocationFilterType = computed(() => {
		if (this.selectedLocationFilterType.value !== null) {
			return this.selectedLocationFilterType.value;
		}

		return this.areContinentsSelected() ? LocationFilterType.CONTINENT : LocationFilterType.COUNTRY;
	});

	private areContinentsSelected(): boolean {
		return this.filterProvider.getFilterSelection(FilterKey.CONTINENT).length > 0;
	}

	public collapsed = computed((): boolean => {
		return this.filterProvider.isFilterCollapsed(FilterKey.COUNTRY);
	});

	public countryFilterHintCount = computed(() => {
		return this.filterProvider.getFilterSelection(FilterKey.COUNTRY).length;
	});

	public continentFilterHintCount = computed(() => {
		return this.filterProvider.getFilterSelection(FilterKey.CONTINENT).length;
	});

	constructor(
		private filterProvider: IFilterProvider,
		public readonly locationFilterProvider: ILocationFilterProvider
	) {}

	public async toggleContinentFilter(): Promise<void> {

		if (this.activeLocationFilterType.value === LocationFilterType.COUNTRY) {

			this.selectedLocationFilterType.value = LocationFilterType.CONTINENT;

			await this.filterProvider.clearFilters([FilterKey.COUNTRY, FilterKey.AREA]);
		}
	}

	public async toggleCountryFilter(): Promise<void> {

		if (this.activeLocationFilterType.value === LocationFilterType.CONTINENT) {

			this.selectedLocationFilterType.value = LocationFilterType.COUNTRY;

			await this.filterProvider.clearFilter(FilterKey.CONTINENT);
		}
	}

	public toggleFilter(): void {
		this.filterProvider.toggleFilterExpandability(FilterKey.COUNTRY);
	}
}
